<template>

  <div class="container-custom">
    <div class="contents-header">
      <div class="separator">
        <p class="breadcrumb-menu">
          <router-link :to="linkRecord" class="ashen-link">健康記録参照</router-link>
          <i class="fas fa-angle-right form-control-color"></i>
          <router-link :to="linkUserRecord" class="ashen-link">関係者健康記録一覧</router-link>
          <i class="fas fa-angle-right form-control-color"></i> 関係者健康記録詳細
        </p>
      </div>
      <div class="card border-0 text-first mt-3">
        <div class="row d-flex justify-content-between">
          <div v-if="prevDateString" class="col-6">
            <router-link :to="linkPrevDate" class="ashen-link mx-1 mt-1" replace>
              <i v-if="prevDateString" class="fas fa-angle-left mt-1 color-88 font-16"></i>
              <span class="ml-3 mx-2 font-weight-bold">{{ prevDateString }} <span
                  v-html="showDay(prevDateString)"></span></span>
            </router-link>
          </div>
          <div v-else class="col-6"></div>
          <div v-if="nextDateString" class="col-6 text-end">
            <router-link :to="linkNextDate" class="ashen-link mx-1 mt-1" replace>
                  <span class="mr-3 mx-1 font-weight-bold">{{ nextDateString }} <span
                      v-html="showDay(nextDateString)"></span></span>

            </router-link>
            <i class="fas fa-angle-right mt-1 mx-1 color-88 font-16"></i>
          </div>
          <div v-else class="col-6"></div>
        </div>
      </div>
    </div>

    <div class="card mt-4">
      <div class="card-header color-33 bg-transparent">
        <img src="../assets/images/racket-gray.svg"/><span
          class="p-l-5">{{ tournamentUserDetails.tournamentName }}</span>
      </div>
      <div class="card-body pl-3">
        <div class="form-holder">
          <div class="row">
            <div class="col-6">
              <label>姓名</label>
              <input v-model="tournamentUserDetails.lastName + ' ' + tournamentUserDetails.firstName"
                     class="form-control mt-1 filter-select" placeholder="" readonly
                     type="text">
              <label>メールアドレス</label>
              <input v-model="tournamentUserDetails.email" class="form-control mt-1 filter-select" readonly
                     type="text">
              <label>日付</label>
              <input :value="getDay(date)" class="form-control mt-1 filter-select" readonly type="text">
              <!--
              <label>体温</label>
              <input v-model="tournamentUserDetails.bodyTemp" class="form-control mt-1 filter-select" placeholder=""
                     readonly
                     type="text">
              <label>平熱を超える発熱</label>
              <input v-model="tournamentUserDetails.answers[0].value" class="form-control mt-1 filter-select"
                     placeholder="" readonly
                     type="text">
              <label>咳やのどの痛みなど風邪の症状</label>
              <input v-model="tournamentUserDetails.answers[1].value" class="form-control mt-1 filter-select"
                     placeholder="" readonly
                     type="text">
              <label>だるさ(倦怠感)、息苦しさ(呼吸困難)</label>
              <input v-model="tournamentUserDetails.answers[2].value" class="form-control mt-1 filter-select"
                     placeholder="" readonly
                     type="text">
              <label>嗅覚や味覚の異常</label>
              <input v-model="tournamentUserDetails.answers[3].value" class="form-control mt-1 filter-select"
                     placeholder="" readonly
                     type="text">
              <label>体が重く感じる、疲れやすい等</label>
              <input v-model="tournamentUserDetails.answers[4].value" class="form-control mt-1 filter-select"
                     placeholder="" readonly
                     type="text">
              <label>新型コロナウイルス感染症陽性とされた者との濃厚接触の有無</label>
              <input v-model="tournamentUserDetails.answers[5].value" class="form-control mt-1 filter-select"
                     placeholder="" readonly
                     type="text">
              <label>同居家族や身近な知人に感染が疑われる方がいる場合</label>
              <input v-model="tournamentUserDetails.answers[6].value" class="form-control mt-1 filter-select"
                     placeholder="" readonly
                     type="text">
              <label>過去14日以内に政府から入国制限、入国後の経過観察を必要とされている国、地域への渡航又は当該在住者との濃厚接触がある場合</label>
              <input v-model="tournamentUserDetails.answers[7].value" class="form-control mt-1 filter-select"
                     placeholder="" readonly
                     type="text">
              <label>過去14日以内に新型コロナPCR検査、もしくは抗原検査で陽性と診断されたことがありますか？</label>
              <input v-model="tournamentUserDetails.answers[8].value" class="form-control mt-1 filter-select"
                     placeholder="" readonly
                     type="text">
              -->
            </div>
            <div class="col-6">
              <label>入場管理番号</label>
              <input v-model="tournamentUserDetails.userId" class="form-control mt-1 filter-select" readonly
                     type="text">
              <label>電話番号</label>
              <input v-model="tournamentUserDetails.phoneNo" class="form-control mt-1 filter-select" readonly
                     type="text">
              <label class="pd">ステータス</label>
              <div class="col">
                <div v-if="tournamentUserDetails.healthStatus"
                     class="d-inline-block"
                     v-html="showHealthStatus(tournamentUserDetails.healthStatus)">
                </div>
                <br>
                <label>疲れについて</label>
                <input :value="getHealthCondition(tournamentUserDetails.heatStrokeQuestionTiredness)"
                       class="form-control mt-1 filter-select"
                       placeholder="" readonly
                       type="text">
                <label>食欲について</label>
                <input :value="getHealthCondition(tournamentUserDetails.heatStrokeQuestionAppetite)"
                       class="form-control mt-1 filter-select"
                       placeholder="" readonly
                       type="text">
                <label>睡眠について</label>
                <input :value="getHealthCondition(tournamentUserDetails.heatStrokeQuestionSleep)"
                       class="form-control mt-1 filter-select"
                       placeholder="" readonly
                       type="text">
                <label>汗について</label>
                <input :value="getHealthCondition(tournamentUserDetails.heatStrokeQuestionSweat)"
                       class="form-control mt-1 filter-select"
                       placeholder="" readonly
                       type="text">
                <label>尿の色について</label>
                <input :value="getPeeColor(tournamentUserDetails.peeColor)" class="form-control mt-1 filter-select"
                       placeholder="" readonly
                       type="text">
                <label>熱中症になったことがある</label>
                <input :value="getAns(tournamentUserDetails.everHadHeatStroke)" class="form-control mt-1 filter-select"
                       placeholder="" readonly
                       type="text">
                <label>熱中症点数</label>
                <input v-model="heatStrokeScore" class="form-control mt-1 filter-select"
                       placeholder="" readonly
                       type="text">
              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <label>備考欄</label>
              <textarea class="form-control" cols="50" readonly rows="10">
                 {{ remarksString }}
               </textarea>
            </div>
          </div>
          <div class="">
            <div class="row">
              <div
                  :class="{ 'button-hide': (tournamentUserDetails.healthStatus === 1 || tournamentUserDetails.healthStatus === 3 || tournamentUserDetails.healthStatus === 5) }"
                  class="col">
                <div class="mt-3">
                  <Modal v-if="!tournamentUserDetails.approval" :data="tournamentUserDetails"
                         :user-record-approval="approvalOn" body=" のステータスを大会承認済に変更します。 " button-title="承認"
                         cancel-title="キャンセル" checkbox-title="全ての「異常あり」にも反映" confirmationMsg="よろしいですか？"
                         is-checkbox=true ok-title="承認" title="大会承認"
                         @getAllCheck="allCheckApprove"></Modal>
                  <Modal v-if="tournamentUserDetails.approval" :data="tournamentUserDetails"
                         :modal-class="'userrecorddetail'"
                         :user-record-approval="approvalOff" body=" のステータスは大会承認済みになっています。 " button-title="承認解除"
                         cancel-title="キャンセル" checkbox-title="全ての「大会承認」にも反映" confirmationMsg="解除してもよろしいですか？"
                         is-checkbox=true ok-title="承認解除" title="大会承認解除"
                         @getAllCheck="allCheckApprove"></Modal>
                </div>
              </div>
              <div class="col text-end">
                <div class="mt-3">
                  <button v-if="!tournamentUserDetails.rejection"
                          class="btn btn-primary height-44 font-16 pt-1 text-center float-end custom_btn bg_black"
                          @click="showModal.reject = true">
                    入場拒否
                  </button>
                  <div id="rejectModal">
                    <transition @enter="startTransitionModal" @after-enter="endTransitionModal"
                                @after-leave="startTransitionModal" @before-leave="endTransitionModal">
                      <div v-if="showModal.reject" ref="modal" class="modal fade">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 id="exampleModalLabel" class="modal-title">入場拒否</h5>
                              <button class="close" type="button"
                                      @click="showModal.reject = !showModal.reject">
                                <span aria-hidden="true">×</span></button>
                            </div>
                            <div class="modal-body">{{ tournamentUserDetails.lastName }}
                              {{ tournamentUserDetails.firstName }}
                              のステータスを入場拒否に変更します。<br> よろしいですか？
                            </div>
                            <div class="modal-footer">
                              <button class="btn btn-secondary"
                                      @click="showModal.reject = !showModal.reject">
                                キャンセル
                              </button>
                              <button class="btn btn-primary" type="button" @click="reject">
                                入場拒否
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </transition>
                    <div ref="backdrop" class="modal-backdrop fade d-none"></div>
                  </div>
                  <button v-if="tournamentUserDetails.rejection"
                          class="btn btn-outline-dark height-44 font-16 pt-1 custom_btn mr-0"
                          @click="showModal.cancelReject = true">
                    入場拒否解除
                  </button>
                  <div id="cancelRejectModal">
                    <transition @enter="startTransitionModal" @after-enter="endTransitionModal"
                                @after-leave="startTransitionModal" @before-leave="endTransitionModal">
                      <div v-if="showModal.cancelReject" ref="modal" class="modal fade">
                        <div class="modal-dialog" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title">入場拒否解除</h5>
                              <button class="close" type="button"
                                      @click="showModal.cancelReject = !showModal.cancelReject">
                                <span aria-hidden="true">×</span></button>
                            </div>
                            <div class="modal-body">{{ tournamentUserDetails.lastName }}
                              {{ tournamentUserDetails.firstName }}
                              のステータスは入場拒否になっています。<br> 解除してもよろしいですか？
                            </div>
                            <div class="modal-footer">
                              <button class="btn btn-secondary"
                                      @click="showModal.cancelReject = !showModal.cancelReject">
                                キャンセル
                              </button>
                              <button class="btn btn-primary" type="button" @click="cancelRejection">
                                入場拒否解除
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </transition>
                    <div ref="backdrop" class="modal-backdrop fade d-none"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {mapGetters} from 'vuex'
import Modal from '../components/modal/Modal'
import Common from '../assets/jsadminclient/common'
import Helper from '../assets/jsadminclient/helper'

export default {
  name: "UserRecordDetail",
  components: {Modal},
  props: {
    tournamentId: {type: Number, default: null},
    userId: {type: String, default: null},
  },
  data() {
    return {
      showModal: {
        reject: '',
        cancelReject: ''
      },
      tournamentUserDetails: {},
      prevDate: '',
      nextDate: '',
      approval: '',
      allCheck: false,
      heatStrokeScore: ''

    }
  },
  mounted() {
    this.getTournamentRecordDetails()
  },
  computed: {
    ...mapGetters(['config']),
    date() {
      return this.$route.query.date
    },
    prevDateString() {
      return this.prevDate === null ? '' : Common.dateFormatConvert(this.prevDate)
    },
    nextDateString() {
      return this.nextDate === null ? '' : Common.dateFormatConvert(this.nextDate)
    },
    linkPrevDate() {
      return {
        name: "UserRecordDetail",
        params: {
          tournamentId: Number(this.tournamentId),
          userId: this.userId,
        },
        query: Common.appendSearchQuery(this.createSearchQuery(), {date: this.prevDate})
      }
    },
    linkNextDate() {
      return {
        name: "UserRecordDetail",
        params: {
          tournamentId: Number(this.tournamentId),
          userId: this.userId,
        },
        query: Common.appendSearchQuery(this.createSearchQuery(), {date: this.nextDate})
      }
    },
    linkRecord() {
      return {
        name: "Record",
        query: Common.removeSearchQuery(this.createSearchQuery(), ['date'])
      }
    },
    linkUserRecord() {
      return {
        name: "UserRecord",
        params: {
          tournamentId: this.tournamentId,
          userId: this.userId,
        },
        query: Common.removeSearchQuery(this.createSearchQuery(), ['date'])
      }
    },
    remarksString() {
      if (this.tournamentUserDetails.healthStatus === 3) {
        return ""
      }
      return this.tournamentUserDetails.remarks
    },
  },
  watch: {
    async $route(to, from) {
      // Called from back or next
      this.getTournamentRecordDetails()
    }
  },
  methods: {
    getDay(date) {
      return `${date} (${Helper.getDay(date)})`
    },
    showDay(date) {
      return Helper.showDay(date)
    },
    showHealthStatus(healthStatus) {
      return Helper.showHealthStatus(healthStatus)
    },
    startTransitionModal() {
      this.$refs.backdrop.classList.toggle("d-block");
      this.$refs.modal.classList.toggle("d-block");
      $(".modal").css({
        'zIndex': '999999'
      })
      $(".modal-dialog").css({
        'margin': '15.75rem auto'
      })
      $("#wrapper").css({
        'zIndex': '0'
      })
    },
    endTransitionModal() {
      this.$refs.backdrop.classList.toggle("show");
      this.$refs.modal.classList.toggle("show");
    },
    allCheckApprove(allCheck) {
      this.allCheck = allCheck
    },
    async approvalOn() {
      this.approval = true;
      this.getUserRecordApproval();
    },
    async approvalOff() {
      this.approval = false;
      this.getUserRecordApproval();
    },
    async getUserRecordApproval() {
      try {
        var item = {
          date: this.$route.query.date,
          approval: this.approval,
          allCheck: this.allCheck
        }
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        this.tournamentUserDetails = await api.tournamentsTournamentIdUserRecordsUserIdApprovalPostAsync(
            this.tournamentId,
            this.userId,
            item
        )

        this.approval ? Common.showToast("大会承認にステータスが変更されました。") : Common.showToast("大会承認が解除されました。")

        await this.getTournamentRecordDetails()

        if (this.tournamentUserDetails != null) {
          this.loading = false
          console.log(this.tournamentUserDetails)
        }
      } catch (e) {
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async reject() {
      this.showModal.reject = false
      try {
        var item = {
          date: this.$route.query.date,
          rejection: true,
        }
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        this.tournamentUserDetails = await api.tournamentsTournamentIdUserRecordsUserIdRejectionPostAsync(
            this.tournamentId,
            this.userId,
            item
        )
        Common.showToast("入場拒否にステータスが変更されました。")
        await this.getTournamentRecordDetails()
        if (this.tournamentUserDetails != null) {
          this.loading = false
        }
      } catch (e) {
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async cancelRejection() {
      this.showModal.cancelReject = false

      try {
        var item = {
          date: this.$route.query.date,
          rejection: false,
        }
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        this.tournamentUserDetails = await api.tournamentsTournamentIdUserRecordsUserIdRejectionPostAsync(
            this.tournamentId,
            this.userId,
            item
        )
        Common.showToast("入場拒否承認が解除されました。")
        await this.getTournamentRecordDetails()
        if (this.tournamentUserDetails != null) {
          this.loading = false
        }
      } catch (e) {
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getTournamentRecordDetails() {
      try {
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        this.tournamentUserDetails = await api.tournamentsTournamentIdUserRecordsUserIdDetailGetAsync(
            this.tournamentId,
            this.userId,
            this.$route.query.date,
            this.$route.query.sortItem,
            this.$route.query.orderItem,
        )

        if (this.tournamentUserDetails != null) {
          this.approval = this.tournamentUserDetails.approval
          this.prevDate = this.tournamentUserDetails.prevDate
          this.nextDate = this.tournamentUserDetails.nextDate
          this.heatStrokeScore = this.tournamentUserDetails.heatStrokeScore
          this.loading = false
        }
        if (this.tournamentUserDetails.bodyTemp == '0.0') {
          this.tournamentUserDetails.bodyTemp = '— —';
        }
      } catch (e) {
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query, null)
    },
    getHealthCondition(no) {
      switch (no) {
        case 5:
          return '非常に悪い'
        case 4:
          return '悪い'
        case 3:
          return '普通'
        case 2:
          return '良い'
        case 1:
          return '非常に良い'
        default:
          return ''
      }
    },
    getPeeColor(no) {
      switch (no) {
        case 1:
          return 'うすい'
        case 3:
          return '普通'
        case 5:
          return '濃い'
        case 7:
          return 'とても濃い'
        default:
          return ''
      }
    },
    getAns(no) {
      switch (no) {
        case 1:
          return 'はい'
        case 2:
          return 'いいえ'
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.content_wrapper {
  padding: 0 32px;
}

.content {
  top: unset !important;
}

.nvlink-container {
  border-bottom: 1px solid #e2e2e2;
}

a {
  color: #333333;
}

.filter-select {
  font-size: 13px;
  height: 48px !important;
  line-height: 13px;
  background: #FAF7F9;
  color: #333333;
}

.modal-body {
  text-align: left;
}

.pd {
  padding: 5px 0;
}

textarea {
  background: #FAF7F9 !important;
  color: #333333;
  min-height: 207px;
  font-size: 13px;
}

.custom_btn {
  width: 100%;
}

.bg_black {
  background-color: #333333;

  &, &:focus:active {
    border-color: #282828;
  }

  &:focus {
    -webkit-box-shadow: 0 0 0 0.25rem rgba(177, 177, 177, 0.5);
    box-shadow: 0 0 0 0.25rem rgba(177, 177, 177, 0.5);
  }
}

.container-custom {
  padding: 0 22px;

  label {
    padding: 17px 0 10px 0;

    &.pd {
      padding: 20px 0;
    }
  }

  .badge-calendar-check {
    background-color: #F0FFEA;
    color: #7EC100;
  }

  .button-hide {
    opacity: 0;
    pointer-events: none;
  }

  .badge-times-circle {
    background-color: #FFEAEB;
    color: #C1001B;
  }
}

</style>
<style lang="scss">
.userrecorddetail {
  .form-holder {
    padding: 0 64px;

    .button_holder {
      padding-left: 40px;
      padding-right: 40px;

      .row {
        .col {
          &:first-child {
            padding-right: 10px;

            > div {
              float: right;
            }
          }

          &:last-child {
            padding-left: 10px;

            > div {
              float: left;
            }
          }

          & > div {
            display: inline-block;

            &:first-child {
              width: 100%;
            }
          }


          > .btn {
            width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 1100px) {
      padding: 0 32px;
      .button_holder {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
    @media screen and (max-width: 1000px) {
      padding: 0 48px;
      .button_holder {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
    @media screen and (max-width: 800px) {
      padding: 0 14px;
      .button_holder {
        padding-left: 20px;
        padding-right: 20px;
      }

    }
  }

}
</style>